<h1 class="titlePage" *ngIf="user && user._id === studentId">Mis experiencias registradas.</h1>
<h1 class="titlePage" *ngIf="!user || user._id !== studentId">Experiencias registradas del estudiante.</h1>

<div class="row introductionContainer" *ngIf="user">
  <div class="col-12">
    <h4 class="titulo-wizard">Experiencias Registradas</h4>
    <p>
      En esta sección, debes registrar únicamente aquellas experiencias ejecutadas que cuenten con todos los respaldos
      necesarios para su validación. Es importante tener en cuenta que estas experiencias serán verificadas antes de ser
      marcadas como aprobadas, lo que contribuirá a tu ascenso en el nivel de competencia correspondiente.
    </p>
    <hr class="hrLine" />
  </div>
</div>

<div class="skills-container">
  <mat-form-field>
    <mat-label for="filterByCategories">Categoria de experiencia</mat-label>
    <mat-select
      id="filterByCategories"
      name="filterByCategories"
      (ngModelChange)="toggleCategorySelection($event)"
      multiple
      [(ngModel)]="selectedCategoryOption">
      <mat-option *ngFor="let option of categories" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label for="filterBySkills">Competencia principal</mat-label>
    <mat-select
      id="filterBySkills"
      name="filterBySkills"
      (ngModelChange)="toggleSkillSelection($event)"
      multiple
      [(ngModel)]="selectedSkillsOption">
      <mat-option *ngFor="let option of skills" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<!-- Add search input field here -->
<div class="search-container">
  <mat-form-field>
    <mat-label for="searchExperiences">Buscar experiencias</mat-label>
    <input
      matInput
      id="searchExperiences"
      name="searchExperiences"
      [(ngModel)]="searchTerm"
      (ngModelChange)="filterExperiences()"
      placeholder="Buscar experiencias por nombre" />
  </mat-form-field>
</div>

<div class="experienceCardContainer">
  <div class="experienceCard">
    <div class="cardComponent" *ngIf="filteredExperiences && filteredExperiences.length > 0">
      <div class="card" *ngFor="let experience of filteredExperiences; let i = index">
        <div class="cardTitle">
          <h5>{{ experience.experienceName }}</h5>
          <h5>{{ experience.status }}</h5>
        </div>
        <div class="cardBody">
          <div [innerHTML]="decodeEntities(trimHtmlContent(experience.experienceDescription))"></div>
          <p class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="" />
            {{ experience.principalSkill }} (Puntaje: {{ experience.skillsScore }})
            <span
              [ngClass]="{
                'low-impact': experience.skillsScore < 20,
                'intermediate-impact': experience.skillsScore >= 20 && experience.skillsScore <= 35,
                'high-impact': experience.skillsScore > 35
              }">
              {{
                experience.skillsScore > 35
                  ? 'Alto impacto'
                  : experience.skillsScore >= 20 && experience.skillsScore <= 35
                  ? 'Impacto intermedio'
                  : 'Bajo impacto'
              }}
            </span>
          </p>
          <div *ngIf="user && user._id === studentId" class="bottomSide">
            <p class="date">
              <img src="../../../assets/experiences/calendario.png" alt="" />
              Fecha de realización : {{ experience.formattedStartDate }} - {{ experience.formattedEndDate }}
            </p>
            <div class="editButton" (click)="openSimulationChart(experience)">Ver simulacion</div>
          </div>
          <p class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="" />
            {{ experience.secondarySkill }} (Puntaje: {{ experience.skillsScore / 2 }})
          </p>

          <div *ngIf="user && user._id === studentId" class="bottomSide">
            <p class="date">
              <img src="../../../assets/experiences/calendario.png" alt="" />
              Fecha de realización : {{ experience.formattedStartDate }} - {{ experience.formattedEndDate }}
            </p>
            <div class="editButton" (click)="editExperienceRecorded(experience)">Ver más</div>
          </div>
        </div>
      </div>
    </div>
    <div class="experienceCard" *ngIf="experiences && experiences.length === 0 && user && user._id === studentId">
      <p>Parece que aún no tienes experiencias registradas. ¡Crea una!</p>
    </div>
  </div>

  <div *ngIf="user && user._id === studentId">
    <button (click)="recordExperience()" class="addAnotherButton">
      <img src="../../../assets/experiences/more.png" alt="" />
      Registrar experiencia
    </button>
  </div>
</div>
